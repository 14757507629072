import m from 'mithril'

class FabButton {
    view(vnode) {
        return m('a.md-btn-floating.md-btn-large.waves-effect.waves-light.red', vnode.attrs, [
            m('i.material-icons', 'add')
        ])
    }
}

export default FabButton