import m from 'mithril'
import moment from 'moment'
import GaugeComponent from './_gauge'
import RowLineComponent from './_rowLine'
import RowLineOfPricGauge from './_pricentLine'
import stream from 'mithril/stream'
import {
    TextBox,
    Dialog
} from 'components'
import { Total, Tube, Outside } from 'app/models'


class DashboardPage {
    constructor() {
        this.voters = ['林金結', '吳琪銘', '李縉穎', '劉瑞娜', '曾怡晴']
        this._search = ""
        this.refreshId = null
        this.tu_total = 0
        this.xi_total = 0
        this.total = stream(0)
        this.voter_total = []
        Total.fetch().then((response) => {
            this.voter_total = response
            this.voter_total.map((item) => {
                this.total(this.total() + item.total)
            })
            if (this.total() == 0) {
                let temp = this.voter_total[0]
                this.voter_total[0] = this.voter_total[1]
                this.voter_total[1] = temp
            }
        })
        this.tu_tubes = 0
        this.xi_tubes = 0
        Tube.fetch().then((response) => {
            response.map((item) => {
                if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
                    if (parseInt(item.serial) <= 2009) {
                        this.tu_tubes += 1
                    }
                    else {
                        this.xi_tubes += 1
                    }
                }
            })
        })
        this.records = []
        Tube.fetch_record().then((response) => {
            this.records = response
        })

        this.total_type = true
        // this.outsides = [{
        //     name: "端傳媒科技",
        //     url: "https://theinitium.com/project/20200110-taiwan-2020-election-live/data/data.json",
        //     type: 0
        // }, {
        //     name: "東森新聞",
        //     url: "https://s3-ap-northeast-1.amazonaws.com/img.vote2020/vote/president_vote_allarea_data.json",
        //     type: 1
        // }]
        // this.one = 0
        // this.two = 0
        // this.three = 0
        // this.outside_index = 0
        // this.outside_data = []
        // Outside.fetch(this.outsides[this.outside_index%2]).then((response) => {
        //     this.outside_data = chooses_data_from_json(this.outside_index % 2, response)
        // })
    }
    // chooses_data_from_json(type, datas) {
    //     this._one = 0
    //     this._two = 0
    //     this._three = 0
    //     if (type == 0) {
    //         datas.candidates.map((item) => {
    //             if (item.name == "宋楚瑜") {
    //                 this._one = item.votes
    //             } else if (item.name == "韓國瑜") {
    //                 this._two = item.votes
    //             } else if (item.name == "蔡英文") {
    //                 this._three = item.votes
    //             }
    //         })
    //     }
    //     else if (type == 1){
    //         datas.data.map((item) => {
    //             if (item.name == "宋楚瑜") {
    //                 this._one = item.vote_tt
    //             } else if (item.name == "韓國瑜") {
    //                 this._two = item.vote_tt
    //             } else if (item.name == "蔡英文") {
    //                 this._three = item.vote_tt
    //             }
    //         })
    //     }
    //     return [this._one, this._two, this._three]
    // }

    oncreate(vnode) {
        this.refreshId = setInterval(function () {
            this.refreshId = null
            this.voter_total = []
            this.total(0)
            Total.fetch().then((response) => {
                this.voter_total = response
                this.voter_total.map((item) => {
                    this.total(this.total() + item.total)
                })
                if (this.total() == 0) {
                    let temp = this.voter_total[0]
                    this.voter_total[0] = this.voter_total[1]
                    this.voter_total[1] = temp
                }
            })
            Tube.fetch().then((response) => {
                this.tu_tubes = 0
                this.xi_tubes = 0
                response.map((item) => {
                    if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
                        if (parseInt(item.serial) <= 2009) {
                            this.tu_tubes += 1
                        }
                        else {
                            this.xi_tubes += 1
                        }
                    }
                })
            })

            Tube.fetch_record().then((response) => {
                this.records = response
            })


        }.bind(this), 30000);
    }
    onremove(vnode) {
        clearInterval(this.refreshId)
    }

    view() {
        return [
            m(".row.no-gutters.pt-3.pt-md-0.px-md-0.px-2", [
                m(".col-md-6.pr-md-4.col-12.py-3.py-md-0", [
                    m(".card.text-white.px-md-5.px-1.py-4", {
                        style: {
                            background: "rgba(0,0,0,0.5)",
                            borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                        }
                    }, [
                            m('.row', [
                                m('.col-md-12.d-flex.flex-column.justify-content-between', [
                                    m(".card-body.p-0", [
                                        m("span.card-title.d-inline-block.pb-1", {
                                            style: {
                                                borderBottom: "10px solid #009e96",
                                                fontSize: "1.75rem"
                                            }
                                        }, "全區開票比例"),
                                    ]),
                                    m(RowLineComponent, {
                                        datas: this.voter_total,
                                        total: this.total
                                    })
                                ]),
                            ])

                        ])
                ]),
                m(".col-md-6.pr-md-4.col-12", [
                    m(".row", [
                        m(".col-12", [
                            m(".card.text-white.h-100.px-md-5.py-4.px-1", {
                                style: {
                                    background: "rgba(0,0,0,0.5)",
                                    borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                }
                            }, [
                                    m('.row', [
                                        m('.col-md-12.d-flex.flex-column.justify-content-between', [
                                            m(".card-body.p-0", [
                                                m("span.card-title.d-inline-block.pb-1", {
                                                    style: {
                                                        borderBottom: "10px solid #ffff00",
                                                        fontSize: "1.75rem"
                                                    }
                                                }, "票筒比例"),
                                                m(".row.d-flex.justify-content-end.pt-4", [
                                                    m(".col-md-3.col-6.text-center.order-0", [
                                                        m(".text-left.d-inline-block", [
                                                            m("span.d-block.font-weight-light", {
                                                                style: {
                                                                    fontSize: "1.3rem"
                                                                }
                                                            }, "總票數"),
                                                            m("span.font-weight-bolder", {
                                                                style: {
                                                                    fontSize: "2rem"
                                                                }
                                                            }, this.total().toLocaleString()),
                                                        ])

                                                    ]),
                                                    m(".col-md-3.col-6.text-center.order-2.order-md-1.pt-4.pt-md-0", [
                                                        m(".text-left.d-inline-block", [
                                                            m("span.d-block.font-weight-light", {
                                                                style: {
                                                                    fontSize: "1.3rem"
                                                                }
                                                            }, "土城區"),
                                                            m("span.font-weight-bolder", {
                                                                style: {
                                                                    fontSize: "2rem"
                                                                }
                                                            }, `${this.tu_tubes} / `),
                                                            m("span.font-weight-bolder", {
                                                                style: {
                                                                    fontSize: "1.3rem"
                                                                }
                                                            }, "144"),
                                                        ])
                                                    ]),
                                                    m(".col-md-3.col-6.text-center.order-3.order-md-2.pt-4.pt-md-0", {
                                                        style: {
                                                            borderLeft: "medium solid #5a767a",
                                                            borderRight: "medium solid #5a767a",
                                                        }
                                                    }, [
                                                            m(".text-left.d-inline-block", [

                                                                m("span.d-block.font-weight-light", {
                                                                    style: {
                                                                        fontSize: "1.3rem"
                                                                    }
                                                                }, "三峽區"),
                                                                m("span.font-weight-bolder", {
                                                                    style: {
                                                                        fontSize: "2rem"
                                                                    }
                                                                }, `${this.xi_tubes} / `),
                                                                m("span.font-weight-bolder", {
                                                                    style: {
                                                                        fontSize: "1.3rem"
                                                                    }
                                                                }, "70"),
                                                            ]),
                                                        ]),
                                                    m(".col-md-3.col-6.text-center.order-1.order-md-4", [
                                                        m(".text-left.d-inline-block", [
                                                            m("span.d-block.font-weight-light", {
                                                                style: {
                                                                    fontSize: "1.3rem"
                                                                }
                                                            }, "已開"),
                                                            m("span.font-weight-bolder", {
                                                                style: {
                                                                    fontSize: "2rem"
                                                                }
                                                            }, (((((this.xi_tubes + this.tu_tubes) / 214).toFixed(2)) * 100)).toFixed(2) + "%"),
                                                        ])

                                                    ]),
                                                ])
                                            ]),


                                        ]),
                                    ])

                                ])
                        ]),
                        m(".col-12.pt-4", [
                            m('.row.h-100', [
                                // (this.total_type)?[
                                m(".col-md-7", [
                                    m(".card.text-white.h-100.px-md-5.py-4.px-1", {
                                        style: {
                                            background: "rgba(0,0,0,0.5)",
                                            borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                        }
                                    }, [
                                            m(".card-body.p-0", [
                                                m("span.card-title.d-inline-block.pb-1", {
                                                    style: {
                                                        borderBottom: "10px solid #00b7ee",
                                                        fontSize: "1.75rem"
                                                    },
                                                    // onclick:(e)=>{
                                                    //     this.total_type = false
                                                    // }
                                                }, "候選人比例"),
                                                m(GaugeComponent, {
                                                    datas: this.voter_total,
                                                    total: this.total
                                                }),
                                            ])
                                        ])
                                ]),
                            // ]:[
                            //     m(".col-md-7", [
                            //         m(".card.text-white.h-100.px-md-5.py-4.px-1", {
                            //             style: {
                            //                 background: "rgba(0,0,0,0.5)",
                            //                 borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                            //             }
                            //         }, [
                            //                 m(".card-body.p-0", [
                            //                     m("span.card-title.d-inline-block.pb-1", {
                            //                         style: {
                            //                             borderBottom: "10px solid #00b7ee",
                            //                             fontSize: "1.75rem"
                            //                         },
                            //                         onclick: (e) => {
                            //                             this.total_type = true
                            //                         }
                            //                     }, this.outsides[this.outside_index%2].name),
                            //                     m(RowLineOfPricGauge, {
                            //                         datas: this.outside_data,
                            //                     }),
                            //                 ])
                            //             ])
                            //     ]),
                            //     ],
                                m(".col-md-5.pt-4.pt-md-0", [
                                    m(".card.text-white.h-100.px-md-5.py-4.px-1", {
                                        style: {
                                            background: "rgba(0,0,0,0.5)",
                                            borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                        }
                                    }, [
                                            m(".card-body.p-0", [
                                                m("span.card-title.d-inline-block.pb-1", {
                                                    style: {
                                                        borderBottom: "10px solid #eb6877",
                                                        fontSize: "1.75rem"
                                                    }
                                                }, "開票紀錄"),
                                                m(".row.no-gutters", [
                                                    this.records.map((record) => {
                                                        return [
                                                            m(".col-12.py-3.d-flex.justify-content-between", {
                                                                style: {
                                                                    borderBottomStyle: "solid",
                                                                    borderWidth: "1px"
                                                                }
                                                            }, [
                                                                    m("span", moment(record.updated_at).format("HH:MM")),
                                                                    m("span", record.serial + "  號票筒"),
                                                                    m("span", "已開票")

                                                                ]),
                                                        ]
                                                    }),

                                                ])
                                            ])
                                        ])
                                ]),
                                

                            ])


                        ])

                    ]),

                ]),



            ]),

        ]
    }
}

export default DashboardPage