import m from 'mithril'
import stream from 'mithril/stream'
import uuid from 'uuid'
import {
    TextField,
    TextArea,
    Dialog
} from 'components'
import {
    Account
} from "app/models";


class UserNameDialog {
    constructor() {        
        Account.load(window.USER_ID).then((response) => {
            this.name = response.name
            this.email = response.email
        })
    }

    update(id,name) {
        // Account.validate()
        Account.update(id, name).then(() => {
            Account.fetch().then(() => {
                Dialog.close()
            })
        })
    }
    close(returnValue) {
        setTimeout(() => {
            Dialog.close()
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return [
            m('.modal-dialog', [
                m('.modal-content', [
                    m('.modal-header.pb-0.text-white', {
                        style: {
                            background: '#424242'
                        }
                    }, [
                        m('.modal-title', [
                            m('.h5', [
                                '管理員資料修改'
                            ])
                        ]),
                        m("button.close.text-white", {
                                onclick: this.close
                            },
                            m("span[aria-hidden='true']",
                                m.trust("&times;")
                            )
                        )
                    ]),
                    m('.modal-body', [
                        m('.container-fluid', [
                            m('.col-12.pt-3', [
                                m('h5', [
                                    m('span.badge badge-primary', '姓名')
                                ])
                            ]),
                            m('.col-12', [
                                m('input.form-control[placeholder="請輸入您的名稱"]', {
                                    value: this.name,
                                    oninput: (e) => {
                                        this.name = e.target.value
                                    },
                                }),

                            ])
                        ]),
                        m('.container-fluid', [
                            m('.col-12.pt-3', [
                                m('h5', [
                                    m('span.badge badge-primary', 'E-Mail')
                                ])
                            ]),
                            m('.col-12', [
                                m('input.form-control[readonly]', {
                                    value: this.email,
                                    oninput: (e) => {
                                        this.email = e.target.value
                                    },
                                }),

                            ])
                        ])
                    ]),
                    m('.modal-footer', [
                        m("button.btn.btn-success[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.update(window.USER_ID, this.name)
                                }
                            },
                            "儲存"
                        ),
                        m("button.btn.btn-outline-secondary[type='button']", {
                                onclick: this.close
                            },
                            "取消"
                        )
                    ])
                ])
            ])
        ]
    }
}

export default UserNameDialog