import m from 'mithril'
import classNames from 'classnames'

class TextField {
    constructor(vnode) {
        this.error = vnode.attrs.error
        this.model = vnode.attrs.model
        this.field = vnode.attrs.field
        this.value = this.model[this.field]
        this.attrs = Object.assign({}, vnode.attrs.input)
        this.cssClass = vnode.attrs.class
    }

    getValue() {
        return this.model[this.field]
    }

    setValue(value) {
        this.model[this.field] = value
    }

    onbeforeupdate(vnode) {
        this.error = vnode.attrs.error
    }
    view(vnode) {
        this.model = vnode.attrs.model
        return [
            m(`input.${vnode.attrs.class}`, Object.assign({}, {
                type: 'text',
                value: this.getValue(),
                readonly: vnode.attrs.readonly,
                disabled: vnode.attrs.disabled,
                placeholder: vnode.attrs.placeholder,
                pattern: vnode.attrs.pattern,
                oninput: (e) => {
                    this.setValue(e.target.value)
                    this.error = this.model.valid(this.field, vnode.attrs.validOption)
                }
            }, this.attrs, {
                class: classNames({
                    'form-control': !vnode.attrs.readonly,
                    'form-control-plaintext w-100': vnode.attrs.readonly,
                    'is-invalid': this.error
                })
            }))
        ]
    }
}

class Textbox {
    constructor(vnode) {
        this.error = false
    }
    getError(model, field) {
        if (model.errors === undefined) {
            return false
        }
        this.error = (model.errors[field]) ? model.errors[field][0] : null
    }
    view({
        attrs
    }) {
        this.getError(attrs.model, attrs.field)
        return [
            m('.form-group', attrs.layout, [
                (attrs.label) ?
                m('label.mb-1', [
                    attrs.label
                ]) : '',
                m(TextField, {
                    model: attrs.model,
                    field: attrs.field,
                    error: this.error,
                    input: attrs.input,
                    readonly: attrs.readonly,
                    disabled: attrs.disabled,
                    class: attrs.class,
                    placeholder: vnode.attrs.placeholder,
                    pattern: vnode.attrs.pattern,

                }),
                (this.error) ?
                m('.invalid-feedback', [
                    this.error.toString()
                ]) :
                (attrs.help) ?
                m('small.form-text.text-muted', [
                    attrs.help
                ]) : ''
            ])
        ]
    }

}

export default Textbox