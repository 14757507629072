import m from 'mithril';
import Chart from 'chart.js';

export default {
    view(vnode) {
        const model = vnode.attrs.model;

        return m(`.${model.config.type}`,[
            m('#canvas-holder', [
                m('canvas#chart-area', {
                    style:{
                        height:"360px"
                    },
                    oncreate(vnode) {
                        const ctx = vnode.dom.getContext('2d');
                        model.instance = new Chart(ctx, model.config);
                    }
                })
            ]),
        ])
    }
};