
import m from 'mithril'
import echarts from 'echarts'
import stream from 'mithril/stream';

class BaseGauge {
    constructor(vnode) {
        this.option = {
            title: {
                show: 'false',
            },
            tooltip: {
                trigger: 'item',
                formatter: "{b} <br/> 共 {c} 票 ({d}%)"
            },
            legend: {
                // orient: 'vertical',
                // top: 'middle',
                bottom: 0,
                left: 'center',
                data: ['林金結', '吳琪銘', '李縉穎', '劉瑞娜', '曾怡晴'],
                textStyle:{
                    color: '#FFF',
                    fontSize: 20
                }
            },
            series: [
                {
                    type: 'pie',
                    radius: '70%',
                    center: ['50%', '40%'],
                    selectedMode: 'single',
                    data: [],                    
                    label: {
                        normal: {
                            position: 'inner'
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    color: ['#89c997', '#00b7ee', '#f39800', '#eb6877', '#ffd200']
                }
            ]
        }
    }
    oncreate(vnode) {
        const canvas = vnode.dom.querySelector('.widget_canvas')
        const chart = echarts.init(canvas)
        this.option.series[0].data = []
        vnode.attrs.datas.map((item)=>{
            this.option.series[0].data.push({
                value: item.total, name: item.number, label: { fontSize: 16 }
            })
        })        
        chart.setOption(this.option, true);
    }
    onupdate(vnode) {
        const canvas_wrapper = vnode.dom.querySelector('.widget_canvas')
        this.chart = echarts.init(canvas_wrapper)
        this.option.series[0].data = []
        vnode.attrs.datas.map((item) => {
            this.option.series[0].data.push({
                value: item.total, name: item.number,label:{fontSize:16}
            })
        })
        this.chart.setOption(this.option, true);
    }
    view(vnode) {
        return m('.mx-auto', {
            style: {
                position: 'relative'
            }
        }, [
                m('.widget_canvas', {
                    style: {
                        width: '100%',
                        height: '380px'
                    },
                    onclick: (e) => {
                        e.preventDefault()
                    }
                }),
                m('h2.text-center', {
                    style: {
                        position: 'absolute',
                        bottom: '20px',
                        left: 0,
                        right: 0
                    }
                }, [
                        m('a.text-white', {
                            href: '#',
                            // onclick: (e) => {
                            //     e.preventDefault()
                            //     if (Auth.IsAuth){
                            //     //open 細項
                            //     ModalComponent.show(SheetComponent, {
                            //         baseName: vnode.attrs.baseName,
                            //         projectId: vnode.attrs.projectId
                            //     })
                            // }
                            // }
                        }, vnode.attrs.baseName)

                    ])
            ])
    }
}
export default BaseGauge