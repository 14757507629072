import m from 'mithril'
import classNames from 'classnames'

class TextArea {
    constructor(vnode) {
        this.label = false
        this.active = false
        if (vnode.attrs.hasOwnProperty('label')) {
            this.label = vnode.attrs.label
        }
    }
    oncreate(vnode) {
        const target = vnode.dom.querySelector('textarea')
        this.resizeHandle(target)
    }
    onupdate(vnode) {

    }
    resizeHandle(target) {

        if (target.scrollHeight == 0) {
            return
        }
        const overflows = this.getParentOverflows(target)

        let heightOffset = null;
        let cachedHeight = null;

        const docTop = document.documentElement && document.documentElement.scrollTop;
        target.style.height = '';
        target.style.height = (target.scrollHeight) + 'px';

        // used to check if an update is actually necessary on window.resize
        let clientWidth = target.clientWidth;

        // prevents scroll-position jumping
        overflows.forEach(el => {
            el.node.scrollTop = el.scrollTop
        });

        if (docTop) {
            document.documentElement.scrollTop = docTop;
        }
        const styleHeight = Math.round(parseFloat(target.style.height));
        const computed = window.getComputedStyle(target, null);

        // Using offsetHeight as a replacement for computed.height in IE, because IE does not account use of border-box
        var actualHeight = computed.boxSizing === 'content-box' ? Math.round(parseFloat(computed.height)) : target.offsetHeight;

        // The actual height not matching the style height (set via the resize method) indicates that 
        // the max-height has been exceeded, in which case the overflow should be allowed.

        if (actualHeight < styleHeight) {
            if (computed.overflowY === 'hidden') {
                target.style.overflowY = 'scroll'
                // this.changeOverflow('scroll');
                this.resizeHandle(target)
                actualHeight = computed.boxSizing === 'content-box' ? Math.round(parseFloat(window.getComputedStyle(ta, null).height)) : target.offsetHeight;
            }
        } else {
            // Normally keep overflow set to hidden, to avoid flash of scrollbar as the textarea expands.
            if (computed.overflowY !== 'hidden') {
                target.style.overflowY = 'hidden'
                // this.changeOverflow('hidden');
                this.resizeHandle(target)
                actualHeight = computed.boxSizing === 'content-box' ? Math.round(parseFloat(window.getComputedStyle(ta, null).height)) : target.offsetHeight;
            }
        }

        if (cachedHeight !== actualHeight) {
            cachedHeight = actualHeight;
        }
    }
    getParentOverflows(el) {
        const arr = [];

        while (el && el.parentNode && el.parentNode instanceof Element) {
            if (el.parentNode.scrollTop) {
                arr.push({
                    node: el.parentNode,
                    scrollTop: el.parentNode.scrollTop,
                })
            }
            el = el.parentNode;
        }

        return arr;
    }
    resize(e) {
        this.resizeHandle(e.target)
    }
    view(vnode) {
        return m('.md-input-field.md-form-control.d-flex.flex-fill', [
            m('textarea.form-control.w-100', {
                style: {
                    minHeight: '6rem'
                },
                disabled: vnode.attrs.disabled,
                readonly: vnode.attrs.readonly,
                oninput: (e) => {
                    vnode.attrs.oninput(e)
                    this.resize(e)
                }
            }, vnode.attrs.value),
            (this.label) ? [
                m('label', {
                    class: classNames({
                        'active': vnode.attrs.value || this.active
                    }),
                }, this.label)
            ] : '',
        ])
    }
}

export default TextArea