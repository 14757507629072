import m from 'mithril'
import classNames from 'classnames'

class Pagination {
    constructor(vnode) {
        this.hasFirst = false
        this.hasMoreNext = false
        this.hasMorePrev = false
        this.hasLast = false
        this.pages = []
        this.pageRange = 4
        this.computePages(vnode.attrs)
    }
    getPageRanges(start, end) {

        let ranges = []
        for (let i = start; i <= end; i++) {
            ranges.push(i)
        }
        this.pages = ranges
    }
    computePages(attrs) {
        let pageRange = this.pageRange
        let totalPage = attrs.pageCount

        let rangeStart = attrs.pageNo - pageRange

        let rangeEnd = attrs.pageNo + pageRange

        if (rangeEnd > totalPage) {
            rangeEnd = totalPage
            rangeStart = totalPage - pageRange * 2;
            rangeStart = rangeStart < 1 ? 1 : rangeStart;
        }
        if (rangeStart <= 1) {
            rangeStart = 1;
            rangeEnd = Math.min(pageRange * 2 + 1, totalPage);
        }
        this.hasFirst = (rangeStart == 1)
        this.hasLast = (rangeEnd == totalPage)
        this.getPageRanges(rangeStart, rangeEnd)
    }
    onbeforeupdate({ attrs }) {
        this.computePages(attrs)
    }
    view({ attrs }) {
        return [
            (attrs.pageCount > 0) ? [
                m('nav', [
                    m('ul.pagination',{
                        style:{
                            backgroundColor:"rgba(0,0,0,0)"
                        }
                    }, [
                        // m('li.page-item', {
                        //     class: classNames({
                        //         'disabled': this.hasFirst
                        //     }),
                        //     style: {
                        //         backgroundColor: "rgba(0,0,0,0)"
                        //     }
                        // }, [
                        //         m('a.page-link', {
                        //             style: {
                        //                 backgroundColor: "rgba(0,0,0,0)"
                        //             },
                        //             href: '#',
                        //             onclick: (e) => {
                        //                 e.preventDefault()
                        //                 attrs.pageUrl(1)
                        //             }
                        //         }, [
                        //                 m('span', m.trust('&laquo;'))
                        //             ])
                        //     ]),
                        // m('li.page-item', {
                        //     class: classNames({
                        //         'disabled': this.hasFirst
                        //     }),
                        // }, [
                        //         m('a.page-link', {
                        //             style: {
                        //                 backgroundColor: "rgba(0,0,0,0)"
                        //             },
                        //             href: '#',
                        //             onclick: (e) => {
                        //                 e.preventDefault()
                        //                 attrs.pageUrl(attrs.pageNo-1)
                        //             }
                        //         }, [
                        //                 m('span', m.trust('&lsaquo;'))
                        //             ])
                        //     ]),
                        this.pages.map((item) => {
                            return [
                                m('li.page-item', {
                                    class: classNames({
                                        'active': item == attrs.pageNo
                                    })
                                }, [
                                        m('a.page-link', {
                                            style: {
                                                backgroundColor: "rgba(0,0,0,0)",
                                                color:"#eee"
                                            },
                                            href: '#',
                                            onclick: (e) => {
                                                e.preventDefault()
                                                attrs.pageUrl(item)
                                            }
                                        }, item)
                                    ]),
                            ]
                        }),
                        // m('li.page-item', {
                        //     class: classNames({
                        //         'disabled': this.hasLast
                        //     })
                        // }, [
                        //         m('a.page-link', {
                        //             style: {
                        //                 backgroundColor: "rgba(0,0,0,0)"
                        //             },
                        //             href: '#',
                        //             onclick: (e) => {
                        //                 e.preventDefault()
                        //                 attrs.pageUrl(attrs.pageNo+1)
                        //             }
                        //         }, [
                        //                 m('span', m.trust('&rsaquo;'))
                        //             ])
                        //     ]),

                        // m('li.page-item', {
                        //     class: classNames({
                        //         'disabled': this.hasLast
                        //     })
                        // }, [
                        //         m('a.page-link', {
                        //             style: {
                        //                 backgroundColor: "rgba(0,0,0,0)"
                        //             },
                        //             href: '#',
                        //             onclick: (e) => {
                        //                 e.preventDefault()
                        //                 attrs.pageUrl(attrs.pageCount)
                        //             }
                        //         }, [
                        //                 m('span', m.trust('&raquo;'))
                        //             ])
                        //     ]),


                    ])
                ])
            ] : ''

        ]
    }
}

export default Pagination