import m from "mithril";

class Editor {

    constructor(vnode) {
        this.content = vnode.attrs.content
        this.editor = null

    }
    oncreate(vnode) {
        this.editor = CKEDITOR.replace(vnode.dom, {
            // filebrowserUploadUrl: `${window.BASE_URL}/api/upload/ckeditor?`,
            // filebrowserImageUploadUrl: `${window.BASE_URL}/api/upload/ckeditor?type=Images`,
            toolbar: [
                ['Font', 'FontSize', '-', 'Bold', 'Italic', '-'],
                ['NumberedList', 'BulletedList', '-', 'Link', 'Unlink', '-', 'Cut', 'Copy', 'Paste'],
                ['Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                ['EasyImageUpload', 'Image', 'Table', 'TextColor', 'BGColor', '-', 'Source']
            ],
            easyimage_toolbar: ['EasyImageAlignLeft', 'EasyImageAlignCenter', 'EasyImageAlignRight', 'EasyImageAlt'],
            easyimage_class: 'figure',
            easyimage_styles: {
                full: {
                    attributes: {
                        'class': 'figure-full'
                    }
                },
                side: {
                    attributes: {
                        'class': 'figure-side'
                    }
                },
                alignLeft: {
                    attributes: {
                        'class': 'figure-align-left'
                    }
                },
                alignCenter: {
                    attributes: {
                        'class': 'figure-align-center'
                    }
                },
                alignRight: {
                    attributes: {
                        'class': 'figure-align-right'
                    }
                }
            }
        })
        // this.editor = CKEDITOR.replace(vnode.dom)
        let _index = CKEDITOR.instances

        this.editor.on("instanceReady", (e) => {
            this.editor.setData(this.content())
            this.editor.on('change', (e) => {
                this.content(e.editor.getData())
            })
            this.editor.on('afterInsertHtml', (e) => {
                this.content(e.editor.getData())
            })
        })
    }
    // onbeforeupdate(vnode) {
    //     this.editor.setData(this.content())
    // }
    onremove(vnode) {
        if (this.editor) {
            CKEDITOR.remove(this.editor)
        }
    }
    view({
        attrs
    }) {
        return m('.article_editor.h-100.w-100', [
            m.trust(this.content())
        ])
    }
}

export default Editor