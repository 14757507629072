import m from 'mithril'
import {
    Textbox
}
from 'components'
import Dialog from 'components/dialog'

class ProfileDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
    }
    save() {
        if (this.model.validate()) {
            return false
        }
        this.model.save().then((response) => {
            setTimeout(() => {
                Dialog.close(true)
                m.redraw()
            }, 150)
        })
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return m('.modal-dialog', [
            m('.modal-content.shadow-lg', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        `個人資料`
                    ]),
                    m('button[type="button"].close', {
                        onclick: (e) => {
                            this.close()
                        }
                    }, [
                        m('span', m.trust('&times;'))
                    ])
                ]),
                m(".modal-body.py-3", [
                    m('.container-fluid', [
                        m('.row', [
                            m('.col-10.mx-auto', [
                                m(Textbox, {
                                    label: '姓氏',
                                    model: this.model,
                                    field: 'familyName',
                                    input: {
                                        maxlength: 100
                                    }
                                })
                            ]),
                            m('.col-10.mx-auto', [
                                m(Textbox, {
                                    label: "名字",
                                    model: this.model,
                                    field: 'lastName',
                                    input: {
                                        maxlength: 100
                                    }
                                })
                            ])
                        ])
                    ])
                ]),
                m('.modal-footer', [
                    m('button[type="button"].btn.btn-outline-secondary', {
                        onclick: this.close
                    }, '關閉'),
                    m('button[type="button"].btn.btn-primary', {
                        onclick: this.save.bind(this)
                    }, '儲存')
                ])
            ])
        ])


    }
}

export default ProfileDialog