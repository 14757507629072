import m from 'mithril'
import stream from 'mithril/stream'
import uuid from 'uuid'
import {
    TextField,
    TextArea,
    Dialog
} from 'components'
import {
    Account
} from "app/models";


class UserPermissionDialog {
    constructor(vnode) {
        this.id = vnode.attrs.id
        this.permission = []
        Account.load(this.id).then((response) => {
            this.name = response.name
            this.email = response.email
            this.permission = JSON.parse(response.permission) || []
        })
        this.basic = ["article", "point", "advert", "news", "courses",
            "dive-center", "instructor", "order", "student", "member",
            "product", "ad", "cloud", "level", "management",
            "management\/product-items", "material", "export", "account", "dashboard","payment",
            "authconfig"
        ]
    }

    checkAuth(claim) {
        let query = claim;

        const filterItems = this.permission.findIndex((item) => {
            return item == query
        })
        return filterItems > -1
    }

    toggleAuth(claim) {
        let query = claim;
        const filterIndex = this.permission.findIndex((item) => {
            return item == query
        })
        if (~filterIndex) {
            return this.permission.splice(filterIndex, 1)
        }

        this.permission.push(query)
    }

    update(id, permission) {
        // Account.validate()
        Account.updatePermission(id, permission).then(() => {
            Account.fetch().then(() => {
                Dialog.close()
            })
        })
    }
    close(returnValue) {
        setTimeout(() => {
            Dialog.close()
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return [
            m('.modal-dialog', [
                m('.modal-content', [
                    m('.modal-header.pb-0.text-white', {
                        style: {
                            background: '#424242'
                        }
                    }, [
                        m('.modal-title', [
                            m('.h5', [
                                '權限修改'
                            ])
                        ]),
                        m("button.close.text-white", {
                                onclick: this.close
                            },
                            m("span[aria-hidden='true']",
                                m.trust("&times;")
                            )
                        )
                    ]),
                    m('.modal-body', [
                        m('.container-fluid', [
                            m('.col-12', [
                                m('h5', [
                                    m('span', this.name)
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.permission.length == this.basic.length,
                                        onclick: (e) => {
                                            if (this.permission.length == this.basic.length) {
                                                this.permission = []
                                            } else {
                                                this.basic.map((item) => {
                                                    this.toggleAuth(item)
                                                })
                                            }
                                        }
                                    }),
                                    m('span', {
                                        style: {
                                            fontSize: "1.5rem"
                                        }
                                    }, '全選')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('dashboard'),
                                        onclick: (e) => {
                                            this.toggleAuth('dashboard')
                                        }
                                    }),
                                    m('span', '首頁')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('article'),
                                        onclick: (e) => {
                                            this.toggleAuth('article')
                                        }
                                    }),
                                    m('span', '網頁管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('advert'),
                                        onclick: (e) => {
                                            this.toggleAuth('advert')
                                        }
                                    }),
                                    m('span', '首頁輪播圖管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('news'),
                                        onclick: (e) => {
                                            this.toggleAuth('news')
                                        }
                                    }),
                                    m('span', '最新消息')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('courses'),
                                        onclick: (e) => {
                                            this.toggleAuth('courses')
                                        }
                                    }),
                                    m('span', '課程管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('dive-center'),
                                        onclick: (e) => {
                                            this.toggleAuth('dive-center')
                                        }
                                    }),
                                    m('span', '潛水中心')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('instructor'),
                                        onclick: (e) => {
                                            this.toggleAuth('instructor')
                                        }
                                    }),
                                    m('span', '教練管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('order'),
                                        onclick: (e) => {
                                            this.toggleAuth('order')
                                        }
                                    }),
                                    m('span', '商品訂單管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('student'),
                                        onclick: (e) => {
                                            this.toggleAuth('student')
                                        }
                                    }),
                                    m('span', '學員管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('member'),
                                        onclick: (e) => {
                                            this.toggleAuth('member')
                                        }
                                    }),
                                    m('span', '會員管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('product'),
                                        onclick: (e) => {
                                            this.toggleAuth('product')
                                        }
                                    }),
                                    m('span', '商品管理')
                                ])
                            ]),
                            // m('.col-12', [
                            //     m('label', [
                            //         m('input[type="checkbox"]', {
                            //             checked: this.checkAuth('blog'),
                            //             onclick: (e) => {
                            //                 this.toggleAuth('blog')
                            //             }
                            //         }),
                            //         m('span', 'Blog管理')
                            //     ])
                            // ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('ad'),
                                        onclick: (e) => {
                                            this.toggleAuth('ad')
                                        }
                                    }),
                                    m('span', '廣告管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('point'),
                                        onclick: (e) => {
                                            this.toggleAuth('point')
                                        }
                                    }),
                                    m('span', '點數管理')
                                ])
                            ]),

                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('cloud'),
                                        onclick: (e) => {
                                            this.toggleAuth('cloud')
                                        }
                                    }),
                                    m('span', '下載區檔案管理')
                                ])
                            ]),

                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('level'),
                                        onclick: (e) => {
                                            this.toggleAuth('level')
                                        }
                                    }),
                                    m('span', '教練等級、續約設定')
                                ])
                            ]),
                            // m('.col-12', [
                            //     m('label', [
                            //         m('input[type="checkbox"]', {
                            //             checked: this.checkAuth('forlevel'),
                            //             onclick: (e) => {
                            //                 this.toggleAuth('forlevel')
                            //             }
                            //         }),
                            //         m('span', '課程費用設定')
                            //     ])
                            // ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('management'),
                                        onclick: (e) => {
                                            this.toggleAuth('management')
                                        }
                                    }),
                                    m('span', '地區及運費設定')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('management/product-items'),
                                        onclick: (e) => {
                                            this.toggleAuth('management/product-items')
                                        }
                                    }),
                                    m('span', '產品項目設定')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('authconfig'),
                                        onclick: (e) => {
                                            this.toggleAuth('authconfig')
                                        }
                                    }),
                                    m('span', '教練發卡權限設定')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('payment'),
                                        onclick: (e) => {
                                            this.toggleAuth('payment')
                                        }
                                    }),
                                    m('span', '訂單管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('material'),
                                        onclick: (e) => {
                                            this.toggleAuth('material')
                                        }
                                    }),
                                    m('span', '教材管理')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('export'),
                                        onclick: (e) => {
                                            this.toggleAuth('export')
                                        }
                                    }),
                                    m('span', '資料匯出')
                                ])
                            ]),
                            m('.col-12', [
                                m('label', [
                                    m('input[type="checkbox"]', {
                                        checked: this.checkAuth('account'),
                                        onclick: (e) => {
                                            this.toggleAuth('account')
                                        }
                                    }),
                                    m('span', '帳號管理')
                                ])
                            ]),

                        ]),
                    ]),
                    m('.modal-footer', [
                        m("button.btn.btn-success[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.update(this.id, this.permission)
                                }
                            },
                            "儲存"
                        ),
                        m("button.btn.btn-outline-secondary[type='button']", {
                                onclick: this.close
                            },
                            "取消"
                        )
                    ])
                ])
            ])
        ]
    }
}

export default UserPermissionDialog