import m from 'mithril'

class TextField {
    constructor() {

    }
    getValue() {
        return this.model[this.field]
    }
    setValue(value) {
        this.model[this.field] = value
    }
    view({
        attrs
    }) {
        return m('input', object.assign({}, {
                type: 'text',
                value: this.getValue(),
                oninput: (e) => {
                    let _value = e.target.value
                    if (attrs.oninput) {
                        _value = this.events.oninput(e)
                    }
                    attrs.setValue(_value)
                    if (attrs.error) {
                        attrs.error = this.model.valid(this.field)
                    }
                },
                onchange: (e) => {
                    this.error = this.model.valid(this.field)
                }
            })),
            (this.error) ?
            m('.invalid-feedback', [
                this.error.toString()
            ]) : ''
    }
}

export default TextField