import m from 'mithril'
import classNames from 'classnames'
import { Dialog } from 'components'
import {
    UserDialog,
    PasswordDialog,
    UserNameDialog,
    UserPermissionDialog
} from './dialogs'
import {
    Account
} from 'app/models'
import DetailDialog from './dialogs/detail'

class AccountPage {
    constructor() {
        this.accounts = []
        Account.fetch().then((response)=>{
            this.accounts = response
        })
    }
    view() {
        return m('.main-content.bgc-grey-100.d-flex.align-items-stretch', [
            m(".container-fluid",
                m(".row",
                    m(".col",
                        m(".", [
                            m(".d-flex.d-flex", [
                                m("h3.d-inline-block.m-auto.mb-3.text-white",
                                    "帳號管理"
                                ),
                            ]),
                            m(".pt-2", [
                                (Account.loading) ? [m('h5.text-center.text-white.py-3', '資料讀取中...')] :
                                    [(this.accounts.length == 0) ? [m('h2.py-3.text-center.text-white', '尚無資料')] : [
                                        m(".row",
                                            this.accounts.map((item) => {
                                                return m(".col-md-2.py-2",
                                                    m(".card.btn.btn-light",
                                                        m(".card-body", [
                                                            m(".card-title.h3", item.name),
                                                            m(".card-subtitle.mb-2.text-muted", item.email),
                                                            m('a[href="#"][title="修改權限"]', {
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    Dialog.show(UserPermissionDialog, {
                                                                        attrs: {
                                                                            id: item.id
                                                                        },
                                                                    })

                                                                }
                                                            }, m('i.fa.fa-check-square-o', {
                                                                style: {
                                                                    fontSize: '1.4rem'
                                                                }

                                                            })),
                                                            (window.USER_ID != item.id) ? [

                                                                m('a[href="#"]', {
                                                                    class: classNames({
                                                                        'disabled': (window.USER_ID == item.id)
                                                                    }),
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        if (confirm("是否刪除本帳號?")) {
                                                                            Account.remove(item.id).then(() => {
                                                                                Account.fetch()
                                                                            })
                                                                        }
                                                                    }
                                                                }, m('i.fa.fa-trash-o', {
                                                                    style: {
                                                                        fontSize: '1.4rem'
                                                                    }

                                                                })),
                                                            ] : "",
                                                            // (window.USER_ID == item.id) ? [
                                                                m('a.tool[href="#"][title="修改密碼"]', {
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        Dialog.show(PasswordDialog, {
                                                                            attrs: {
                                                                                id: item.id
                                                                            }
                                                                        })
                                                                    }
                                                                }, [m('i.fa.fa-lock', {
                                                                    style: {
                                                                        fontSize: '1.4rem'
                                                                    }

                                                                })]),
                                                            // ] : '',
                                                            m('a.tool[href="#"][title="修改個人資料"]', {
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    Dialog.show(UserNameDialog, {
                                                                        attrs: {
                                                                            id: item.id
                                                                        },
                                                                        didHide: () => {
                                                                            Account.fetch()
                                                                        }
                                                                    })
                                                                }
                                                            }, [
                                                                    m('i.fa.fa-user-o', {
                                                                        style: {
                                                                            fontSize: '1.4rem'
                                                                        }

                                                                    })
                                                                ])

                                                        ])

                                                    )
                                                )
                                            })
                                        )
                                    ],

                                    m(".pos-f", {
                                        style: {
                                            "right": "40px",
                                            "bottom": "40px"
                                        }
                                    }, [
                                            m("button.btn-danger", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Dialog.show(DetailDialog, {
                                                        attrs: {
                                                            data: this.accounts
                                                        }
                                                    })
                                                }
                                            },
                                                m("i.material-icons",
                                                    "add"
                                                )
                                            )
                                        ])
                                    ]
                            ])
                        ])
                    )
                )
            )
        ])
    }
}
export default AccountPage