import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []

const constraints = {

}

var app = {}
app.xhrConfig = function (xhr) {
    xhr.setRequestHeader("Content-Type", "application/json")
}

  
class Outside extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};

    }

    static get data() {
        return _data;
    }

    static fetch(data) {
        Outside.loading = true
        return m.request({
            method: 'GET',
            withCredentials:true,
            crossDomain: false,
            url: data.url,
            headers: {
                "cache-control":"no-cache",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((response) => {            
            Outside.loading = false
            return response
        })
    }

}

export default Outside