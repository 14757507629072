import m from 'mithril'

class Loading {
    view({
        attrs
    }) {
        // return m('h5.md-loading', {
        //     class: attrs.css
        // }, [
        //     attrs.text,
        //     m('span', '.'),
        //     m('span', '.'),
        //     m('span', '.')
        // ])

        return m('.spinner-border.text-info',
            m('span.sr-only', "Loading...")
        )
    }
}

export default Loading