import m from 'mithril'
import stream from 'mithril/stream'
import uuid from 'uuid'
import {
    TextField,
    TextArea,
    Dialog
} from 'components'
import classNames from 'classnames'
import {
    Account
} from "app/models";


class PasswordDialog {
    constructor() {
        this.password = ''
        this.comfirm_password = ''
        this.status = true
    }

    update(id,password) {
        // Account.validate()
        Account.updateKey(id, password).then(() => {
            Account.fetch().then(() => {
                Dialog.close()
            })
        })
    }
    close(returnValue) {
        setTimeout(() => {
            Dialog.close()
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return [
            m('.modal-dialog', [
                m('.modal-content', [
                    m('.modal-header.pb-0.text-white', {
                        style: {
                            background: '#424242'
                        }
                    }, [
                        m('.modal-title', [
                            m('.h5', [
                                '管理員密碼修改'
                            ])
                        ]),
                        m("button.close.text-white", {
                                onclick: this.close
                            },
                            m("span[aria-hidden='true']",
                                m.trust("&times;")
                            )
                        )
                    ]),
                    m('.modal-body', [
                        m('.container-fluid', [
                            m('.col-12.pt-3', [
                                m('h5', [
                                    m('span.badge badge-primary', '新密碼')
                                ])
                            ]),
                            m('.col-12', [
                                m('input.form-control[type="password"][placeholder="請輸入您的新密碼"]', {
                                    class: classNames({"is-invalid": (!this.status)}),
                                    value: this.password,
                                    oninput: (e) => {
                                        this.password = e.target.value
                                    },
                                }),
                                (!this.status) ? m(".text-danger", '請確認密碼'): ''

                            ])
                        ]),
                        m('.container-fluid', [
                            m('.col-12.pt-3', [
                                m('h5', [
                                    m('span.badge badge-primary', '確認新密碼')
                                ])
                            ]),
                            m('.col-12', [
                                m('input.form-control[type="password"][placeholder="請確認您的新密碼"]', {
                                    class: classNames({
                                        "is-invalid": (!this.status)
                                    }),
                                    value: this.comfirm_password,
                                    oninput: (e) => {
                                        this.comfirm_password = e.target.value
                                    },
                                }),
                                (!this.status) ? m(".text-danger", '請確認密碼'): ''

                            ])
                        ])
                    ]),
                    m('.modal-footer', [
                        m("button.btn.btn-success[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    if (this.password != this.comfirm_password || this.password == ''){
                                        this.status = false
                                    }
                                    else{
                                        this.status = true
                                    }
                                    if(this.status){
                                    this.update(window.USER_ID, this.password)
                                    }
                                }
                            },
                            "儲存"
                        ),
                        m("button.btn.btn-outline-secondary[type='button']", {
                                onclick: this.close
                            },
                            "取消"
                        )
                    ])
                ])
            ])
        ]
    }
}

export default PasswordDialog