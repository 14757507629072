import m from 'mithril'

class Footer {
    view({
        attrs
    }) {
        return [
            m(".footer.w-100",{
                style:{
                    "white-space":"nowrap"
                }
            },
                [
                    m("span.float-right.text-white",{
                        style:{
                            fontSize:"0.5rem"
                        }
                    },
                        [
                            "Copyright ",
                            m.trust("&copy;"),
                            " 2019 Arron. All rights reserved.",
                        ]
                    )
                ]
            )
        ]
    }
}

export default Footer