import m from 'mithril'
import {
    Button,
    Textbox,
    Dialog
} from 'components'
import {
    Account
} from 'app/models'

const close = (e) => {
    e.preventDefault()
    Dialog.close()
}

const submit = (e) => {
    e.preventDefault()
    // Account.validate()
    // console.log(Account.isValidate())
    // if (Account.isValidate()) {
    //     Account.create().then(() => {
    //         Account.fetch().then(() => {
    //             Dialog.close()
    //         })
    //     })
    // }
}

const enterPress = (e) => {
    if (e.keyCode === 13) {
        submit(e)
    }
}

const UserDialog = {
    oninit: (vnode) => {
        Account.load()
        Account.errors = []
    },
    oncreate: (vnode) => {
        vnode.dom.addEventListener('keydown', enterPress)
    },
    onbeforeupdate: (vnode) => {

    },
    onupdate: (vnode) => {

    },
    onbeforeremove: (vnode) => {

    },
    onremove: (vnode) => {
        vnode.dom.removeEventListener('keydown', enterPress)
    },
    view: (vnode) => {
        return m('.modal.fade[tabindex="-1"][role="dialog"][aria-labelledby="exampleModalCenterTitle"][aria-hidden="true"]', 
            m('.modal-dialog.modal-dialog-centered[role="document"]', 
                m('.modal-content', [
                    m('.modal-header', [
                        m('button.close', {
                                onclick: close
                            },
                            m('span', 'x')
                        ),
                        m('h4.modal-title', '帳號設定')
                    ]),
                    m(".modal-body", [
                        m('.dialog_account_container', [
                            m('.form-group',
                                m(Textbox, {
                                    label: '你的名稱',
                                    oninput: (value) => {
                                        Account.data.name = value
                                    },
                                    onblur: (value) => {
                                        Account.validate('name', value)
                                    },
                                    value: Account.data.name,
                                    error: Account.errors.name,
                                    maxlength: '100'

                                })
                            ),
                            m('.form-group',
                                m(Textbox, {
                                    label: '登入帳號email',
                                    oninput: (value) => {
                                        Account.data.email = value
                                    },
                                    onblur: (value) => {
                                        Account.validate('email', value)
                                    },
                                    value: Account.data.email,
                                    error: Account.errors.email,
                                    maxlength: '100'

                                })
                            )
                        ])
                    ]),
                    m(".modal-footer", [
                        m(Button, {
                            onclick: close
                        }, '取消'),
                        m(Button, {
                            onclick: submit
                        }, '儲存')
                    ])
                ])
            )
        )
    }
}

export default UserDialog