import m from 'mithril'
import classNames from 'classnames'
import stream from 'mithril/stream'
import Header from './partials/header'
import Footer from './partials/footer'
import {
    Dialog
} from 'components'

import * as Menu from 'app/models/menu'

class LayoutComponent {
    constructor() {
    }
    checkRoute() {
        let _route = m.route.get()
        return /^\/site/i.test(_route)
    }
    setWidth(val) {
        this.width = val
    }
    view(vnode) {
        this.route = m.route.get()
        return [
            m('.d-flex.flex-column.justify-content-between', {
                style: {

                    backgroundImage: `url('${window.BASE_URL}/img/bg/backdround001.jpg')`,
                    backgroundSize: "100% 100%",
                    height: (window.isMobile) ? "100%" : "100vh",
                    maxHeight: (window.isMobile) ? "100%" : "100vh",
                    minHeight: "100vh",
                }
            }, m(".w-100.h-100.d-flex.flex-column.justify-content-between", {
                style: {
                    background: "rgba(0,0,0,0.2)",
                    padding: (window.isMobile) ? "0px" : "20px 80px",
                }
            }, [
                    m(Header),
                    m(".py-md-3"),
                    m("div.mt-n2.flex-grow-1", {
                        class: classNames({
                            'content-page': true
                        }),
                    },
                        m("div", [vnode.children])
                    ),
                    m(Footer)
                ]),

            ),
            m(Dialog),
        ]
    }
}

export default function (component, opts, auth) {
    return {
        onmatch: (args, requestedPath) => {
            let roles = []
            roles.push("首頁")
            const found = Menu.ManagementItems.find((item) => {
                // if(requestedPath == "/"){
                //     return true
                // }
                // const match = item.regex.exec(requestedPath)
                // if (match) {
                //     return roles.includes(item.auth)
                // }
                return true
            })
            if (!found) {
                m.route.set("/404")
            }
        },
        render: (vnode) => {
            return m(LayoutComponent, m(component))
        }
    }
}