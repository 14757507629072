import m from 'mithril'

class EventBackdrop {
    view(vnode) {
        return m('.event-backdrop', {
            style: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 900
            },
            onclick: (e) => {

            }
        })
    }
}

export default EventBackdrop