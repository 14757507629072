import m from 'mithril'
import moment from 'moment'
import stream from 'mithril/stream'
import classNames from 'classnames'
import {
    TextBox,
    Dialog
} from 'components'
import { Total, Tube } from 'app/models'


class ListPage {
    constructor() {
        this.colors = ['#00b7ee', '#89c997', '#f39800', '#eb6877', '#ffd200']
        this.voters = ['林金結', '吳琪銘', '李縉穎', '劉瑞娜', '曾怡晴']
        if (window.isMobile) {
            this.counts = [""]
        }
        else {
            this.counts = ["", "", "", ""]
        }
        this._datas = []
        Tube.fetch().then((response) => {
            response.map((item) => {
                if (!this._datas.hasOwnProperty(item.category)) {
                    this._datas[item.category] = []
                }
                this._datas[item.category].push(item)
            })
        })
        
    }

    oncreate(vnode) {
        this.refreshId = setInterval(function () {
            this._datas = []
            Tube.fetch().then((response) => {
                response.map((item) => {
                    if (!this._datas.hasOwnProperty(item.category)) {
                        this._datas[item.category] = []
                    }
                    this._datas[item.category].push(item)
                })
            })
        }.bind(this), 5000);
    }
    onremove(vnode) {
        clearInterval(this.refreshId)
    }

    view() {
        return [
            m(".row.no-gutters", [
                m(".col-md-12.pr-md-4.col-12", [

                    m(".row.no-gutters", [

                        this.counts.map((count) => {
                            return [
                                m(".col-md-3.px-3.pt-3.pt-md-0", {
                                    class: classNames({
                                        "position-fixed": window.isMobile

                                    }),
                                    style:{
                                        zIndex:"2099"
                                    }
                                }, [
                                        m(".row.no-gutters.bg-white", [
                                            m(".col-4.border.text-center",
                                                m("span.font-weight-bloder.text-dark", {
                                                    style: {
                                                        fontSize: "1.3rem"
                                                    }
                                                }, "里別"),
                                            ),
                                            this.voters.map((voter) => {
                                                return [
                                                    m(".col.border.text-center",
                                                        m("span.font-weight-bloder.text-dark", {
                                                            style: {
                                                                fontSize: "1.3rem"
                                                            }
                                                        }, voter),
                                                    ),
                                                ]
                                            })
                                        ])
                                    ]),
                            ]

                        }),
                        (window.isMobile)?[
                            m(".col-12",{
                                style:{
                                    minHeight:"80px"
                                }
                            })
                        ]:"",
                        Object.keys(this._datas).map((_data, index) => {
                            let voter1 = 0
                            let voter2 = 0
                            let voter3 = 0
                            let voter4 = 0
                            let voter5 = 0
                            let opened = 0
                            this._datas[_data].map((item) => {
                                if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0){
                                    opened++;
                                }
                                voter1 = voter1 + item.voter1
                                voter2 = voter2 + item.voter2
                                voter3 = voter3 + item.voter3
                                voter4 = voter4 + item.voter4
                                voter5 = voter5 + item.voter5
                            })
                            let _max_index = [voter1, voter2, voter3, voter4, voter5].indexOf(Math.max(voter1, voter2, voter3, voter4, voter5))
                            // console.log("opened", opened, "this._datas[_data].length", this._datas[_data].length)
                            if (_data == "三峽里"){
                                return [m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"),
                                    m(".col-md-3.px-3.py-1", [
                                        m(".row.no-gutters", {
                                            style: {
                                                backgroundColor: (opened == this._datas[_data].length) ?
                                                    this.colors[_max_index] : ""
                                            }
                                        }, [
                                                m(".col-4.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.3rem"
                                                        }
                                                    }, _data + "->" + (this._datas[_data].length - opened)),
                                                ),
                                                m(".col.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.3rem"
                                                        }
                                                    }, voter1),
                                                ),
                                                m(".col.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.3rem"
                                                        }
                                                    }, voter2),
                                                ),
                                                m(".col.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.3rem"
                                                        }
                                                    }, voter3),
                                                ),
                                                m(".col.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.3rem"
                                                        }
                                                    }, voter4),
                                                ),
                                                m(".col.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.3rem"
                                                        }
                                                    }, voter5),
                                                ),
                                            ])
                                    ])
                            ];
                            }
                            return m(".col-md-3.px-3.py-1", [
                                m(".row.no-gutters", {
                                    style: {
                                        backgroundColor: (opened == this._datas[_data].length) ?
                                            this.colors[_max_index] : ""
                                    }
                                }, [
                                        m(".col-4.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.3rem"
                                                }
                                            }, _data + "->" + (this._datas[_data].length - opened)),
                                        ),
                                        m(".col.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.3rem"
                                                }
                                            }, voter1),
                                        ),
                                        m(".col.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.3rem"
                                                }
                                            }, voter2),
                                        ),
                                        m(".col.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.3rem"
                                                }
                                            }, voter3),
                                        ),
                                        m(".col.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.3rem"
                                                }
                                            }, voter4),
                                        ),
                                        m(".col.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.3rem"
                                                }
                                            }, voter5),
                                        ),
                                    ])
                            ])
                        })
                    ])
                ]),

            ]),

        ]
    }
}

export default ListPage