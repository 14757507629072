import m from 'mithril'

class NoData {
    constructor(vnode) {
        this.title = "目前項目沒有任何內容"
        if (vnode.attrs.title) {
            this.title = vnode.attrs.title
        }
    }
    view({
        attrs
    }) {
        return m('.no_data.mx-auto.text-center', [
            m('i.material-icons.large.grey-text.lighten-1', 'lightbulb_outline'),
            m('.h6', this.title)
        ])
    }
}

export default NoData