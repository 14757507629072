
import m from 'mithril'
import echarts from 'echarts'
import stream from 'mithril/stream';


class RowLineGauge {
    constructor(vnode) {
        this.photos = ['lin.jpg', 'wu.jpg', 'lee.jpg', 'ceng.jpg', 'liu.jpg']
        this.colors = [ '#00b7ee','#89c997', '#f39800', '#eb6877', '#ffd200']
    }
    view(vnode) {
        return m(".row.no-gutters", [
            vnode.attrs.datas.map((item) => {
                return [
                    m('.col-12.py-3', [
                        m(".row", [
                            m(".col-2.pr-0.d-flex.justify-content-center.align-items-center", [
                                m("img.rounded-circle.w-100", {
                                    src: `./img/candidate/${this.photos[parseInt(item.id) - 1]}`,
                                })
                            ]),
                            m(".col-10.pl-4", [
                                m(".row", [
                                    m(".col-12", [
                                        m(".d-inline-block.arial_num.font-weight-bolder", {
                                            style: {
                                                fontSize: (window.isMobile)?"1.5rem":"3rem"
                                            }
                                        }, item.total.toLocaleString()),
                                        m(".d-inline-block.pl-4", [
                                            m("span.d-block.arial_num", {
                                                style: {
                                                    fontSize: (window.isMobile) ? "1rem" : "2rem"
                                                }
                                            }, (vnode.attrs.total() != 0) ? ((item.total / vnode.attrs.total()).toFixed(4) * 100).toFixed(1) + " %" : "0%"),
                                            // m("span.d-block", {
                                            //     style: {
                                            //         fontSize: "1rem"
                                            //     }
                                            // }, item.number),
                                        ])
                                    ]),
                                    m(".col-12", {
                                        style: {
                                            opacity: "1",
                                            paddingRight: (vnode.attrs.total() != 0) ?(70-((item.total / vnode.attrs.total()).toFixed(4) * 100).toFixed(1)) + "%" :"100%",
                                        }
                                    }, [
                                            m(".px-3.py-1", {
                                                style: {
                                                    background: this.colors[parseInt(item.id) - 1],
                                                    minHeight: (window.isMobile) ?"1rem":"2.5rem"
                                                }
                                            }, [
                                                ])
                                        ]),
                                ])
                            ])
                        ])

                    ]),



                ]
            }),
        ])
    }
}
export default RowLineGauge