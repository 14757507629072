import m from 'mithril'
import isMobile from 'lib/detect-mobile'
import Layout from 'app/pages/layout'
import * as Page from 'app/pages'
import {
    Account,
} from "app/models";

const root = document.body

window.isMobile = isMobile()

const managerRoutes = {
    '/': Layout(Page.DashboardPage),
    '/insert': Layout(Page.InsertPage),
    '/list': Layout(Page.ListPage),
    '/account': Layout(Page.AccountPage),
    "/:404...": Page.ErrorPage,
}
const editorRoutes = {
    '/': Layout(Page.InsertPage),
    "/:404...": Page.ErrorPage,
}
const userRoutes = {
    '/': Layout(Page.DashboardPage),
    '/list': Layout(Page.ListPage),
    "/:404...": Page.ErrorPage,
}
const Routes = {
    "/:404...": Page.ErrorPage,
}

export function render() {
    m.route(root, '/', managerRoutes)
    // Promise.all([Account.load(window.USER_ID)]).then((response) => {

    //     if (response[0].name == '工程師'){
    //         m.route(root, '/', managerRoutes)
    //     } else if (response[0].name == 'editor'){
    //         m.route(root, '/', editorRoutes)
    //     } else if (response[0].name == 'user') {
    //         m.route(root, '/', userRoutes)
    //     }
    //     else{
    //         m.route(root, '/:404...', Routes)
    //     }
        
    // })
}