import m from 'mithril'
import moment from 'moment'
import stream from 'mithril/stream'
import classNames from 'classnames'
import { Tube } from 'app/models'
import {
    Paging,
    Dialog,
    TextBox
} from 'components'

class InsertPage {
    constructor(vnode) {
        this.model = new Tube()
        this.tu_tube_serials = []
        this.xi_tube_serials = []
        this._select_tube = ""
        // for (let index = 1866; index <= 2079; index++) {
        //     if (index <= 2009) {
        //         this.tu_tube_serials.push(index)
        //     }
        //     else {
        //         this.xi_tube_serials.push(index)
        //     }

        // }
        this._datas = []
        // Tube.fetch().then((response) => {
        //     response.map((item) => {
        //         if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
        //             this._datas.push(item.serial)
        //         }
        //     })
        // })
        this.phone = ""
        this.check = false
        this.error = false
        // Tube.fetchPhone(this.phone).then((response) => {
        //     response.map((item) => {
        //         if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
        //             this._datas.push(item.serial)
        //         }
        //     })
        // })
        // Tube.fetchPhone(this.phone).then((response) => {
        //     this.tu_tube_serials = []
        //     this.xi_tube_serials = []
        //     response.map((item) => {
        //         if (item.serial <= 2009) {
        //             this.tu_tube_serials.push(item.serial)
        //         }
        //         else {
        //             this.xi_tube_serials.push(item.serial)
        //         }
        //         if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
        //             this._datas.push(item.serial)
        //         }
        //     })
        //     if (response.length > 0) {
        //         this.check = true
        //     }
        //     else {
        //         this.error = true
        //     }
        // })
    }
    save() {
        if (this.model.voter1 > 5000 || this.model.voter2 > 5000 || this.model.voter3 > 5000 || this.model.voter4 > 5000 || this.model.voter5 > 5000){
            swal({
                title: "失敗",
                text: "請確認票數正確性!",
                icon: "error",
                buttons: false,
                timer: 2500,
            })
            return false
        }
        else{
        swal({
            title: (this.model.id == 0) ? "新增成功" : "編輯成功!",
            text: (this.model.id == 0) ? "已成功新增資訊，請至列表查看!" : "已成功編輯資訊，請至列表查看!!",
            icon: "success",
            buttons: false,
            timer: 1000,
        })
        this.model.save().then(() => {
            this._select_tube = ""
            this._datas = []
            // Tube.fetchPhone(this.phone).then((response) => {
            //     response.map((item) => {
            //         if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
            //             this._datas.push(item.serial)
            //         }
            //     })
            // })
            Tube.fetchPhone(this.phone).then((response) => {
                this.tu_tube_serials = []
                this.xi_tube_serials = []
                response.map((item) => {
                    if (item.serial <= 2009) {
                        this.tu_tube_serials.push(item.serial)
                    }
                    else {
                        this.xi_tube_serials.push(item.serial)
                    }
                    if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
                        this._datas.push(item.serial)
                    }
                })
                if (response.length > 0) {
                    this.check = true
                }
                else {
                    this.error = true
                }
            })
        })
    }
    }
    view() {
        return [
            m(".container.py-md-0.pt-3", [
                (!this.check) ? [
                    m(".row.no-gutters.d-flex.justify-content-center", [
                        m(".col-md-3.col-8.pt-5", [
                            m(".pb-2",
                                m("span.text-white", "請輸入手機號碼"),
                            ),
                            m(TextBox, {
                                oninput: (e) => {
                                    this.phone = e.target.value
                                },
                                type: "tel",
                                value: this.phone,
                            }),
                            m(".pt-4.d-flex.justify-content-center",
                                m("button.btn.btn-success.px-3.mx-3", {
                                    onclick: (e) => {
                                        Tube.fetchPhone(this.phone).then((response) => {
                                            this.tu_tube_serials = []
                                            this.xi_tube_serials = []
                                            response.map((item) => {
                                                if (item.serial <= 2009) {
                                                    this.tu_tube_serials.push(item.serial)
                                                }
                                                else {
                                                    this.xi_tube_serials.push(item.serial)
                                                }
                                                if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
                                                    this._datas.push(item.serial)
                                                }
                                            })
                                            if (response.length > 0) {
                                                this.check = true
                                            }
                                            else {
                                                this.error = true
                                            }
                                        })
                                    }
                                }, "確認"),
                            ),
                            (this.error) ? [
                                m(".pt-2",
                                    m("span", {
                                        style: {
                                            fontSize: "1.5rem",
                                            color: "#FF0000"
                                        }
                                    }, "此手機號碼尚未註冊！"),
                                ),
                            ] : ""
                        ]),

                    ])
                ] : [
                        (!Tube.loading) ? [
                            (this._select_tube != "") ? [
                                m(".row.no-gutters", [
                                    m(".col-12", [
                                        m(".card", {
                                            style: {
                                                background: "rgba(0,0,0,0.5)",
                                            }
                                        }, [
                                                m(".card-body", [
                                                    m("h5.card-title.text-white", this.model.serial + " - " + this.model.city + this.model.category),
                                                    m("p.card-text.text-white", this.model.contact + " - " + this.model.phone),
                                                    m(".row.no-gutters", [                                                        
                                                        m(".col-12.col-md.px-3", [
                                                            m(".form-group", [
                                                                m("label.text-white.mb-1", "1.林金結"),
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.voter1 = e.target.value
                                                                        },
                                                                        type:"tel",
                                                                        // pattern:"\d*",
                                                                        value: this.model.voter1,
                                                                    })
                                                                ]])
                                                        ]),
                                                        m(".col-12.col-md.px-3", [
                                                            m(".form-group", [
                                                                m("label.text-white.mb-1", "2.吳琪銘"),
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.voter2 = e.target.value
                                                                        },
                                                                        type:"tel",
                                                                        // pattern:"\d*",
                                                                        value: this.model.voter2,
                                                                    })
                                                                ]])
                                                        ]),
                                                        m(".col-12.col-md.px-3", [
                                                            m(".form-group", [
                                                                m("label.text-white.mb-1", "3.李縉穎"),
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.voter3 = e.target.value
                                                                        },
                                                                        type:"tel",
                                                                        // pattern:"\d*",
                                                                        value: this.model.voter3,
                                                                    })
                                                                ]])
                                                        ]),
                                                        m(".col-12.col-md.px-3", [
                                                            m(".form-group", [
                                                                m("label.text-white.mb-1", "4.劉瑞娜"),
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.voter4 = e.target.value
                                                                        },
                                                                        type:"tel",
                                                                        // pattern:"\d*",
                                                                        value: this.model.voter4,
                                                                    })
                                                                ]])
                                                        ]),
                                                        m(".col-12.col-md.px-3", [
                                                            m(".form-group", [
                                                                m("label.text-white.mb-1", "5.曾怡晴"),
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.voter5 = e.target.value
                                                                        },
                                                                        type:"tel",
                                                                        // pattern:"\d*",
                                                                        value: this.model.voter5,
                                                                    })
                                                                ]])
                                                        ]),
                                                        m(".col-12.col-md.px-3", [
                                                            m(".form-group", [
                                                                m("label.text-white.mb-1", "廢票"),
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.void = e.target.value
                                                                        },
                                                                        type:"tel",
                                                                        // pattern:"\d*",
                                                                        value: this.model.void,
                                                                    })
                                                                ]])
                                                        ]),
                                                    ]),

                                                    m(".row.no-gutters.d-flex.justify-content-end.pt-4", [
                                                        m(".col-md-2.col-12.d-flex.justify-content-between", [
                                                            m("button.btn.btn-info.px-3.mx-3", {
                                                                onclick: (e) => {
                                                                    this._select_tube = ""
                                                                    this._datas = []
                                                                    Tube.fetch().then((response) => {
                                                                        response.map((item) => {
                                                                            if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
                                                                                this._datas.push(item.serial)
                                                                            }
                                                                        })
                                                                    })
                                                                }
                                                            }, "返回"),
                                                            m("button.btn.btn-success.px-3.mx-3", {
                                                                onclick: (e) => {
                                                                    swal({
                                                                        title: "請確認以下資訊是否有誤",
                                                                        content: {
                                                                            element: 'div',
                                                                            attributes: {
                                                                                innerHTML: `
                                                                                <div class="row">
                                                                                <div class="col-12 pb-2"><span class="h4" style="font-size:2rem">${this.model.serial + " - " + this.model.city + this.model.category}</span></div>
                                                                                <div class="col-12 py-1"><span class="h4" style="font-size:2rem">1.林金結:${this.model.voter1}</span></div>
                                                                                <div class="col-12 py-1"><span class="h4" style="font-size:2rem">2.吳琪銘:${this.model.voter2}</span></div>
                                                                                <div class="col-12 py-1"><span class="h4" style="font-size:2rem">3.李縉穎:${this.model.voter3}</span></div>
                                                                                <div class="col-12 py-1"><span class="h4" style="font-size:2rem">4.劉瑞娜:${this.model.voter4}</span></div>
                                                                                <div class="col-12 py-1"><span class="h4" style="font-size:2rem">5.曾怡晴:${this.model.voter5}</span></div>
                                                                                <div class="col-12 py-1"><span class="h4" style="font-size:2rem">廢票:${this.model.void}</span></div>
                                                                                </div>`,
                                                                            },
                                                                        },
                                                                        icon: "warning",
                                                                        dangerMode: true,
                                                                        buttons: ["取消", "確認"]
                                                                    })
                                                                        .then((willDelete) => {
                                                                            if (willDelete) {
                                                                                this.save()
                                                                            } else {
                                                                                swal("您的資料未被儲存");
                                                                            }
                                                                        });
                                                                    
                                                                }
                                                            }, "儲存"),
                                                        ]),

                                                    ])
                                                ])
                                            ]),
                                    ]),



                                ])
                            ] : [
                                    m(".row.no-gutters", [
                                        m(".col-12.d-flex.justify-content-between", [
                                            m("span.font-weight-bolder.text-white", {
                                                style: {
                                                    fontSize: "1.5rem"
                                                }
                                            }, "土城區"),
                                            m("button.btn.btn-warning.font-weight-bolder.text-white", {
                                                style: {
                                                    fontSize: "1rem"
                                                },
                                                onclick: (e) => {
                                                    this.check = false
                                                }
                                            }, "重新輸入電話號碼"),
                                        ]),
                                        m(".col-12", [
                                            m(".row.no-gutters", [
                                                this.tu_tube_serials.map((tube) => {
                                                    return [
                                                        m(".col.p-1.text-center", [
                                                            m("button.btn.w-100.rounded-0", {
                                                                class: (this._datas.includes(tube.toString())) ? "" : "btn-outline-light",
                                                                style: {
                                                                    backgroundColor: (this._datas.includes(tube.toString())) ? "#89c997" : "",
                                                                    color: (this._datas.includes(tube.toString())) ? "#FFF" : "",
                                                                },
                                                                type: "button",
                                                                onclick: (e) => {
                                                                    this._select_tube = tube
                                                                    Tube.load(this._select_tube).then((response) => {
                                                                        this.model = new Tube(response)
                                                                    })
                                                                }
                                                            }, tube)
                                                        ])
                                                    ]
                                                })
                                            ])
                                        ]),
                                        m(".col-12.pt-5", [
                                            m("span.font-weight-bolder.text-white", {
                                                style: {
                                                    fontSize: "1.5rem"
                                                }
                                            }, "三峽區")
                                        ]),
                                        m(".col-12", [
                                            m(".row.no-gutters", [
                                                this.xi_tube_serials.map((tube) => {
                                                    return [
                                                        m(".col.p-1.text-center", [
                                                            m("button.btn.w-100.rounded-0", {
                                                                class: (this._datas.includes(tube.toString())) ? "" : "btn-outline-light",
                                                                style: {
                                                                    backgroundColor: (this._datas.includes(tube.toString())) ? "#89c997" : "",
                                                                    color: (this._datas.includes(tube.toString())) ? "#FFF" : "",
                                                                },
                                                                type: "button",
                                                                onclick: (e) => {
                                                                    this._select_tube = tube
                                                                    Tube.load(this._select_tube).then((response) => {
                                                                        this.model = new Tube(response)
                                                                    })
                                                                }
                                                            }, tube)
                                                        ])
                                                    ]
                                                })
                                            ])
                                        ]),
                                    ])
                                ]
                        ] : [
                                m(".row.no-gutters", [
                                    m(".col-12", [
                                        m("span.font-weight-bolder.text-white", {
                                            style: {
                                                fontSize: "1.5rem"
                                            }
                                        }, "資料獲取中...")
                                    ])
                                ])
                            ]
                    ]
            ])

        ]
    }
}

export default InsertPage