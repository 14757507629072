import m from 'mithril'
import stream from 'mithril/stream'

class DatePickerComponent {
    constructor(vnode) {
        this.field = vnode.attrs.field
        this.start = vnode.attrs.start
        this.end = vnode.attrs.end
        this.months = []
        this.years = []
        this.dates = []
        this.year = stream()
        this.month = stream('')
        this.day = stream('')
        this.getYears()
        this.date = vnode.attrs.field
        if (this.field) {
            this.year(this.field.substring(0, 3))
            this.month(this.field.substring(3, 5))
            this.day(this.field.substring(5, 7))
            this.getYears()
            this.getMonths()
            this.getDates()
        }
    }
    getYears() {
        this.years = []
        for (let i = this.start; i < this.end; i++) {
            this.years.push(this.padLeft(i, 3))
        }

        return this.years
    }
    getMonths() {
        this.months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    }
    getDates() {
        let start = 1
        this.dates = []
        let lastDay = 31
        if (this.year() && this.month()) {
            lastDay = new Date(parseInt(this.year()) + 1911, this.month(), 0).getDate()
        }

        for (let i = start; i < start + parseInt(lastDay); i++) {
            this.dates.push(this.padLeft(i, 2))
        }
        return this.dates
    }
    padLeft(str, len) {
        str = '' + str;
        if (str.length >= len) {
            return str;
        } else {
            return this.padLeft("0" + str, len);
        }
    }
    view({
        attrs
    }) {
        return m('.form-inline', [
            m('.col-4.pl-0', [
                m('select.form-control.pl-1', {
                    class: (attrs.error) ? 'is-invalid' : '',
                    disabled: attrs.disabled,
                    onchange: (e) => {
                        this.year(e.target.value)
                        this.getMonths()
                        attrs.date(`${this.year()}${this.month()}${this.day()}`)
                    }
                }, [
                    m('option[disabled][selected]', (!window.isMobile) ?'請選擇':'年' ),
                    this.years.map((item) => {
                        return m('option', {
                            value: item,
                            selected: item == this.year()
                        }, item)
                    })
                ])
            ]),
            m('.col-4.pl-1', [
                m('select.form-control.pl-1', {
                    class: (attrs.error) ? 'is-invalid' : '',
                    disabled: attrs.disabled,
                    onchange: (e) => {
                        this.month(e.target.value)
                        this.getDates()
                        attrs.date(`${this.year()}${this.month()}${this.day()}`)
                    }
                }, [
                    m('option[disabled][selected]', (!window.isMobile) ? '請選擇' : '月'),
                    this.months.map((item) => {
                        return m('option', {
                            value: item,
                            selected: item == this.month()
                        }, item)
                    })
                ])
            ]),
            m('.col-4.pl-2', [
                m('select.form-control.pl-1', {
                    class: (attrs.error) ? 'is-invalid' : '',
                    disabled: attrs.disabled,
                    onchange: (e) => {
                        this.day(e.target.value)
                        attrs.date(`${this.year()}${this.month()}${this.day()}`)
                    }
                }, [
                    m('option[disabled][selected]', (!window.isMobile) ? '請選擇' : '日'),
                    this.dates.map((item) => {
                        return m('option', {
                            value: item,
                            selected: item == this.day()
                        }, item)
                    })
                ])
            ]),
            m('.col-12', [
                (attrs.error) ?
                m('.invalid-feedback.d-block', [
                    attrs.error.toString()
                ]) : ''
            ])
        ])
    }
}
export default DatePickerComponent