import m from 'mithril'
import stream from 'mithril/stream'
import uuid from 'uuid'
import {
    TextField,
    TextArea,
    TextBox,
    Dialog
} from 'components'
import {
    Account
} from "app/models";


class DetailDialog {
    constructor(vnode) {
        this.name = vnode.attrs.name
        this.email = vnode.attrs.email
        this.model = new Account()
        this.model.name = vnode.attrs.name
        this.model.email = vnode.attrs.email
        this.data = vnode.attrs.data
        
    }

    save() {
        this.check = true
        if (!this.model.validate()) {
            this.data.map((item) => {
                if (item.email == this.model.email) {
                    alert("E-mail已使用，請重新輸入")
                    this.check = false
                    return false
                }

            })
            if (this.check) {
                Account.create(this.model.name, this.model.email).then(() => {
                    Account.fetch().then(() => {
                        Dialog.close()
                    })
                })
            }
        }
    }
    close(returnValue) {
        setTimeout(() => {
            Dialog.close()
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return [
            m('.modal-dialog', [
                m('.modal-content', [
                    m('.modal-header.pb-0.text-white', {
                        style: {
                            background: '#424242'
                        }
                    }, [
                        m('.modal-title', [
                            m('.h5', [
                                '管理員帳號新增'
                            ])
                        ]),
                        m("button.close.text-white", {
                                onclick: this.close
                            },
                            m("span[aria-hidden='true']",
                                m.trust("&times;")
                            )
                        )
                    ]),
                    m('.modal-body', [
                        m('.container-fluid', [
                            // m('.col-12.pt-3', [
                            //     m('h5', [
                            //         m('span.badge badge-primary', '姓名')
                            //     ])
                            // ]),
                            m('.col-12', [
                                m(".form-group", [
                                    m(TextBox, {
                                        label: '姓名',
                                        model: this.model,
                                        field: 'name',
                                        input: {
                                            maxlength: 255
                                        }
                                    }),
                                ]),
                                // m('input.form-control[placeholder="請輸入您的名稱"]', {
                                //     value: this.name,
                                //     oninput: (e) => {
                                //         this.name = e.target.value
                                //     },
                                // }),

                            ])
                        ]),
                        m('.container-fluid', [
                            // m('.col-12.pt-3', [
                            //     m('h5', [
                            //         m('span.badge badge-primary', 'E-Mail')
                            //     ])
                            // ]),
                            m('.col-12', [
                                m(".form-group", [
                                    m(TextBox, {
                                        label: 'E-mail',
                                        model: this.model,
                                        field: 'email',
                                        input: {
                                            maxlength: 255
                                        }
                                    }),
                                ]),
                                // m('input.form-control[placeholder="請輸入登入帳號E-Mail"]', {
                                //     value: this.email,
                                //     oninput: (e) => {
                                //         this.email = e.target.value
                                //     },
                                // }),

                            ])
                        ])
                    ]),
                    m('.modal-footer', [
                        m("button.btn.btn-success[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.save()
                                }
                            },
                            "儲存"
                        ),
                        m("button.btn.btn-outline-secondary[type='button']", {
                                onclick: this.close
                            },
                            "取消"
                        )
                    ])
                ])
            ])
        ]
    }
}

export default DetailDialog