import m from 'mithril'

class DropDown {
    view() {
        return m('button.btn.btn-outline-primary.btn-block', {
            //disabled: true,
            onclick: (e) => {

            }
        }, 'DropDown')
    }
}

export default DropDown