import m from 'mithril'
import stream from 'mithril/stream'
import {
    createTextMaskInputElement,
    conformToMask
} from 'text-mask-core'

class MaskField {
    constructor(vnode) {

        this.mask = false
        this.value = vnode.attrs.value
        if (vnode.attrs.hasOwnProperty('mask')) {
            this.mask = vnode.attrs.mask
            vnode.attrs.mask = undefined
        }
    }

    initTextMask(dom, value) {

        this.textMaskInputElement = createTextMaskInputElement({
            inputElement: dom,
            mask: [/[0-1]/, /\d/, /\d/, '年', /[0-1]/, /\d/, '月', /[0-9]/, /\d/, '日'],
            keepCharPositions: false,
            showMask: false
        })

        const {
            conformedValue
        } = conformToMask(value, [/[0-1]/, /\d/, /\d/, '年', /[0-1]/, /\d/, '月', /[0-9]/, /\d/, '日'])
        // this.textMaskInputElement = createTextMaskInputElement(Object.assign({
        //     inputElement: dom
        // }, this.mask))
        // console.log(value)
        this.textMaskInputElement.update(conformedValue)
    }
    onTextMaskInput(event) {
        this.textMaskInputElement.update(event.target.value)
    }
    oncreate(vnode) {
        if (this.mask) {
            const dom = vnode.dom.querySelector('input')
            this.initTextMask(dom, vnode.attrs.value)
        }
    }
    maskValue(value) {
        const result = conformToMask(value, this.mask.mask, this.mask)
        return result.conformedValue
    }
    view(vnode) {
        return m('div', [
            m('input[type="text"].form-control', {
                oninput: (e) => {
                    this.onTextMaskInput(e)
                    vnode.attrs.oninput(e)
                },
                value: vnode.attrs.value
            })
        ])
    }
}

export default MaskField